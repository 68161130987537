import {request} from "@/network/request";

//获取对应标签所有数据
export function GetTagsBlogList(tagsid,page,pagesize) {
    return request({
        method: 'GET',
        url: '/api/Tags/GetTagsBlogList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params: {
            tagsid: tagsid,
            page:page,
            pagesize:pagesize
        }
    })
}

//获取对应分类所有数据
export function GetClassifyBlogList(classifyid,page,pagesize) {
    return request({
        method: 'GET',
        url: '/api/Tags/GetClassifyBlogList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'},
        params: {
            classifyid: classifyid,
            page:page,
            pagesize:pagesize
        }
    })
}

//获取对应分类所有数据
export function GetBlogLinkList() {
    return request({
        method: 'GET',
        url: '/api/Tags/GetBlogLinkList',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

//-------------------------------------------------------------下载工具------------------------------------------

//获取工具类下载
export function GetBlogTool() {
    return request({
        method: 'GET',
        url: '/api/BlogTool/GetBlogTool',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

//获取代码类下载
export function GetBlogToolCode() {
    return request({
        method: 'GET',
        url: '/api/BlogTool/GetBlogToolCode',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}

//获取常用类下载
export function GetBlogToolCommon() {
    return request({
        method: 'GET',
        url: '/api/BlogTool/GetBlogToolCommon',
        headers: { 'content-type': 'application/x-www-form-urlencoded'}
    })
}
