<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="24" style="text-align: left;margin-bottom: 10px;font-size: 16px;color: #1989fa;font-family:微软雅黑 ">
          文件下载-工具类
        </el-col>
        <el-divider></el-divider>
      </el-row>
      <el-row>
        <el-col :span="6" style="text-align: center;margin-bottom: 20px" v-for="item in toollist">
          <el-link :href="item.Downlink" target="_blank" :underline="false">
          <el-image
              style="width: 100px; height: 100px"
              :src="item.Imagesurl===null||item.Imagesurl===''?'https://gss3.bdstatic.com/84oSdTum2Q5BphGlnYG/timg?wapp&quality=80&size=b150_150&subsize=20480&cut_x=0&cut_w=0&cut_y=0&cut_h=0&sec=1369815402&srctrace&di=53b7dd6222ec81a049d8c4b9b87fdd2f&wh_rate=null&src=http%3A%2F%2Fimgsrc.baidu.com%2Fforum%2Fpic%2Fitem%2F810a19d8bc3eb1356486d7a7a41ea8d3fd1f446c.jpg':item.Imagesurl"
              fit="cover">
          </el-image>
          <div style="font-size: 14px;margin-top: 4px">{{item.Tittle}}</div>
          </el-link>
        </el-col>
      </el-row>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-row>
        <el-col :span="24" style="text-align: left;margin-bottom: 10px;font-size: 16px;color: #1989fa;font-family:微软雅黑 ">
          文件下载-代码类
        </el-col>
        <el-divider></el-divider>
      </el-row>
      <el-row>
        <el-col :span="6" style="text-align: center;margin-bottom: 20px" v-for="item in codelist">
          <el-link :href="item.Downlink" target="_blank" :underline="false">
            <el-image
                style="width: 100px; height: 100px"
                :src="item.Imagesurl===null||item.Imagesurl===''?'https://gss3.bdstatic.com/84oSdTum2Q5BphGlnYG/timg?wapp&quality=80&size=b150_150&subsize=20480&cut_x=0&cut_w=0&cut_y=0&cut_h=0&sec=1369815402&srctrace&di=53b7dd6222ec81a049d8c4b9b87fdd2f&wh_rate=null&src=http%3A%2F%2Fimgsrc.baidu.com%2Fforum%2Fpic%2Fitem%2F810a19d8bc3eb1356486d7a7a41ea8d3fd1f446c.jpg':item.Imagesurl"
                fit="cover">
            </el-image>
            <div style="font-size: 14px;margin-top: 4px">{{item.Tittle}}</div>
          </el-link>
        </el-col>
      </el-row>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-row>
        <el-col :span="24" style="text-align: left;margin-bottom: 10px;font-size: 16px;color: #1989fa;font-family:微软雅黑 ">
          文件下载-常用类
        </el-col>
        <el-divider></el-divider>
      </el-row>
      <el-row>
        <el-col :span="6" style="text-align: center;margin-bottom: 20px" v-for="item in commonlist">
          <el-link :href="item.Downlink" target="_blank" :underline="false">
            <el-image
                style="width: 100px; height: 100px"
                :src="item.Imagesurl===null||item.Imagesurl===''?'https://gss3.bdstatic.com/84oSdTum2Q5BphGlnYG/timg?wapp&quality=80&size=b150_150&subsize=20480&cut_x=0&cut_w=0&cut_y=0&cut_h=0&sec=1369815402&srctrace&di=53b7dd6222ec81a049d8c4b9b87fdd2f&wh_rate=null&src=http%3A%2F%2Fimgsrc.baidu.com%2Fforum%2Fpic%2Fitem%2F810a19d8bc3eb1356486d7a7a41ea8d3fd1f446c.jpg':item.Imagesurl"
                fit="cover">
            </el-image>
            <div style="font-size: 14px;margin-top: 4px">{{item.Tittle}}</div>
          </el-link>
        </el-col>
      </el-row>
    </el-card>

  </div>
</template>

<script>
import {GetBlogTool, GetBlogToolCode, GetBlogToolCommon, GetTagsBlogList} from "@/network/tags";

export default {
  name: "Tool",
  data(){
    return{
      toollist:[],
      codelist:[],
      commonlist:[],
    }
  },
  created() {
    //加载工具数据
    this.inittool()
  },
  methods:{
    //加载工具数据
    inittool(){
      //工具类
      GetBlogTool().then(res => {
        this.toollist =res.data
      }).catch(err => {
        console.log(err);
      })

      //代码类
      GetBlogToolCode().then(res => {
        this.codelist =res.data
      }).catch(err => {
        console.log(err);
      })

      //常用类
      GetBlogToolCommon().then(res => {
        this.commonlist =res.data
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>

<style scoped>

</style>